import React, { Component } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import Index from './Index/Index';
import About from './About/About';
import Contact from './Contact/Contact';
import Safety from './Safety/Safety';
import Assets from './Asset/Assets';
import Founder from './Founder/Founder';

class App extends Component {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/" component={Index} />
            <Route exact path="/about" component={About} />
            <Route path="/contact" component={Contact} />
            <Route path="/products-and-services" component={Safety} />
            <Route path="/what-we-do" component={Assets} />
            <Route path="/our-founder" component={Founder} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
