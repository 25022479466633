import React, { Component } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import WOW from 'wowjs';

import { GiOilRig } from 'react-icons/gi';

import { TiFlowMerge } from 'react-icons/ti';
import { BiAlarmOff } from 'react-icons/bi';
import { GiFlexibleStar, GiPayMoney } from 'react-icons/gi';

import NavBar from '../components/NavBar/NavBar';
import Footer from '../components/Footer/Footer';

import drink from './image/drink.gif';

import './Index.css';
import { Helmet } from 'react-helmet-async';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bgClass: 'hero-index1',
      currentNo: 1,
    };
  }

  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
    // this.interval = setInterval(() => {
    //   if (this.state.currentNo === 1) {
    //     this.setState({ bgClass: 'hero-index2', currentNo: 2 });
    //   } else if (this.state.currentNo === 2) {
    //     this.setState({ bgClass: 'hero-index3', currentNo: 3 });
    //   } else {
    //     this.setState({ bgClass: 'hero-index1', currentNo: 1 });
    //   }
    // }, 5000);
  }

  componentWillUnmount() {
    // clearInterval(this.interval);
  }

  render() {
    var className = 'hero-global hero-index1'; //+ this.state.bgClass;
    return (
      <div>
        <Helmet>
          <title>Usnergy consulting - Home Page</title>
          <meta
            name="description"
            content="Our mission at Usnergy consulting is to be a deeply
                    technical, commercial, and entrepreneurial organisation."
          />
          <link rel="canonical" href="/" />
        </Helmet>
        <NavBar />
        <div className={className}>
          <div className="container">
            <Row>
              <Col sm>
                <div className="header-text">
                  <h3 className="wow fadeInUp">{/* OUR SERVICES */}</h3>
                  <h2 className="wow fadeInUp">
                    Helping our clients make distinctive and substantial
                    improvements <br />
                  </h2>
                  <p>
                    Achieving greatness requires the amalgamation of numerous
                    elements.
                  </p>
                </div>
              </Col>
              <Col sm></Col>
            </Row>
          </div>
        </div>
        <div class="juice" style={{ paddingTop: 0 }}>
          <div className="container">
            <Row>
              <Col sm>
                <div className="never wow fadeInUp">
                  <h3>Link 16 Products</h3>
                  <p>
                    Developing next generation Link 16 technologies today for a
                    dominant battlespace tomorrow.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div class="environment" style={{ paddingTop: 0 }}>
          <div className="container">
            <Row>
              <Col sm>
                <div className="never wow fadeInUp">
                  <h3>Series-EV</h3>
                  <p>
                    Putting all-electric, zero emissions transit solutions
                    within your reach
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="world-class" style={{ paddingTop: 100 }}>
          <div className="container text-center wow fadeInUp">
            {/* <GiOilRig className="icon" /> */}
            <h3>PURPOSE</h3>
            <p>To help create positive, enduring change in the world.</p>
          </div>
        </div>
        <div
          className="world-class"
          style={{ paddingTop: 100, paddingBottom: 100 }}
        >
          <div className="container text-center wow fadeInUp">
            <h3>MISSION</h3>
            <p>
              To help our clients make distinctive, lasting, and substantial
              improvements in their performance and to build a great firm that
              attracts, develops, excites, and retains exceptional people.
            </p>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default Index;
