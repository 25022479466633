import React, { Component } from 'react';

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import WOW from 'wowjs';

import NavBar from '../components/NavBar/NavBar';
import Footer from '../components/Footer/Footer';

import { FcWorkflow } from 'react-icons/fc';
import { FaRegStar } from 'react-icons/fa';

import './About.css';
import { Helmet } from 'react-helmet-async';

class About extends Component {
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }
  render() {
    const contentStyle = {
      background: 'white',
      borderRadius: '9px',
      color: '',
      fontWeight: 200,
    };
    return (
      <div>
        <Helmet>
          <title>Usnergy consulting - About Page</title>
          <meta
            name="description"
            content="Our mission at Usnergy consulting is to be a deeply
                    technical, commercial, and entrepreneurial organisation."
          />
          <link rel="canonical" href="/about" />
        </Helmet>
        <NavBar />
        <div className="hero-about about-bg">
          <div className="container">
            <Row>
              <Col sm>
                <div className="header-text">
                  <h3 className="wow fadeInUp">About Us.</h3>
                  <p className="wow fadeInUp ">
                    Usnergy is an international defense, aerospace and security
                    company which delivers a full range of products and services
                    for air, land as well as advanced electronics, security,
                    information technology solutions and customer support
                    services.
                  </p>
                </div>
              </Col>
              <Col sm></Col>
            </Row>
          </div>
        </div>

        <div className="safety-write" style={{ paddingTop: 150 }}>
          <div className="container">
            <p className="wow fadeInUp p-text">
              At Usnergy, we provide some of the world's most advanced,
              technology-led defence, aerospace and security solutions.
              Collaborating with clients and manufacturers, we serve as an
              intermediary for support of products and systems, contributing to
              the enhancement of military capabilities, safeguarding national
              security and individuals, and ensuring the security of critical
              information and infrastructure.
              <br />
              <br />
              We help our clients make distinctive, lasting, and substantial
              improvements in their performance and to build a great firm that
              attracts, develops, excites, and retains exceptional people.
            </p>
            <Row>
              <Col sm>
                <br />
                <p
                  className="wow fadeInUp p-text"
                  style={{
                    fontWeight: 'bold',
                    fontSize: 25,
                    marginBottom: -15,
                  }}
                >
                  We have an important role in society because we:
                </p>
                <ul className="custom-list">
                  <li>help our customers to provide security and safety;</li>
                  <li>
                    contribute to the economic prosperity of the places where
                    our people live and work;
                  </li>
                  <li>
                    support high value jobs in our business and in our supply
                    chains;
                  </li>
                  <li>
                    value our people and their diversity so they can fulfil
                    their potential in an inclusive and supportive working
                    environment;
                  </li>
                  <li>
                    seek to identify opportunities for individuals from
                    disadvantaged backgrounds;
                  </li>
                  <li>
                    support employees’ rights in relation to freedom of
                    association;inspire and excel in the work we do - the
                    technologies we develop and the talent we build;
                  </li>
                  <li>
                    develop cutting-edge technologies to sustain the competitive
                    strength of the Company in global markets;
                  </li>
                  <li>
                    create best-in-class products and services by forging strong
                    relationships with our suppliers and partners;
                  </li>
                  <li>care for and support our local communities; and</li>
                  <li>
                    use our knowledge and technologies to reduce the
                    environmental impacts of our activities. We have set
                    ourselves the target of achieving net zero greenhouse gas
                    emissions across our operations by 2030.
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default About;
