import React, { Component } from 'react';

import 'react-vertical-timeline-component/style.min.css';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import WOW from 'wowjs';

import NavBar from '../components/NavBar/NavBar';
import Footer from '../components/Footer/Footer';

import './Safety.css';
import { Helmet } from 'react-helmet-async';
import link16 from './image/product.jpg';
import seriesEv from './image/seriesEv.png';
import transponder from './image/transponder.jpg';
import athena from './image/athena.jpg';
import radar from './image/radar.jpg';

class Safety extends Component {
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>Usnergy consulting - Products and Services</title>
          <meta
            name="description"
            content="Our mission at Usnergy consulting is  To help our clients make distinctive, lasting, and substantial improvements in their performance and to build a great firm that attracts, develops, excites, and retains exceptional people."
          />
          <link rel="canonical" href="/safety" />
        </Helmet>
        <NavBar />
        <div className="hero-safety safety-bg">
          <div className="container">
            <Row>
              <Col sm>
                <div className="header-text">
                  <h3 className="wow fadeInUp">Products and Services</h3>
                  <p className="wow fadeInUp ">
                    At USNERGY we serve, supply and protect those who serve and
                    protect us, in a corporate culture that is performance
                    driven and values led.
                  </p>
                </div>
              </Col>
              <Col sm></Col>
            </Row>
          </div>
        </div>
        <div className="safety-write" style={{ paddingTop: 150 }}>
          <div className="container">
            <Row>
              <Col sm>
                <p className="wow fadeInUp p-text">
                  <img src={link16} alt="link16" style={{ width: '100%' }} />
                  <br />
                  <strong style={{ color: 'black', fontSize: 35 }}>
                    Link 16 Products
                  </strong>
                  <br />
                  <br />
                  For more than two decades, Data Link Solutions (DLS), a joint
                  venture of Usnergy consulting, has developed and produced the
                  world standard in tactical data link technologies, creating
                  affordable, high performance, high reliability Link 16
                  terminals for 40 different platforms in 30 allied countries,
                  plus various NATO organizations. DLS is the only supplier that
                  manufactures, delivers, and supports all Link 16 variants.
                  <br />
                  <br />
                  <img
                    src={seriesEv}
                    alt="seriesEv"
                    style={{ width: '100%' }}
                  />
                  <br />
                  <strong style={{ color: 'black', fontSize: 35 }}>
                    Series-EV
                  </strong>
                  <br />
                  Putting all-electric, zero emissions transit solutions within
                  your reach
                  <br />
                  <br />
                  <img
                    src={transponder}
                    alt="transponder"
                    style={{ width: '100%' }}
                  />
                  <br />
                  <strong style={{ color: 'black', fontSize: 35 }}>
                    AN/DPX-7 Reduced Size Transponder (RST)
                  </strong>
                  <br />
                  Next gen capabilities with SWaP-C benefits
                  <br />
                  <br />
                  Our AN/DPX-7 RST meets tomorrow's military and civil IFF
                  specs, including Mode 5, Mode S, and ADS-B functionality in a
                  reduced size, lower cost unit.
                  <br />
                  <br />
                  <img src={athena} alt="athena" style={{ width: '100%' }} />
                  <br />
                  <strong style={{ color: 'black', fontSize: 35 }}>
                    Athena™ 640 Family of Products
                  </strong>
                  <br />
                  The Athena™ 640 is the first commercially available, uncooled
                  thermal focal plane array to use 12 micron pixels.
                  <br />
                  <br />
                  <br />
                  <img src={radar} alt="radar" style={{ width: '100%' }} />
                  <br />
                  <strong style={{ color: 'black', fontSize: 35 }}>
                    Future radar - enabling our customers to stay ahead of the
                    threat curve
                  </strong>
                  <br />
                  As complex threats become increasingly sophisticated, we’re
                  continuing to collaborate closely with our customers, industry
                  partners and the supply chain to rapidly deliver affordable
                  world-class radar capability to maintain operational advantage
                  by staying ahead of the threat curve.
                </p>
              </Col>
            </Row>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Safety;
