import React, { Component } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import WOW from 'wowjs';

import NavBar from '../components/NavBar/NavBar';
import Footer from '../components/Footer/Footer';

import { FcWorkflow } from 'react-icons/fc';
import { FaRegStar } from 'react-icons/fa';

import tomPic from './image/tom.jpg';

import './Founder.css';
import { Helmet } from 'react-helmet-async';

class Founder extends Component {
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }
  render() {
    const contentStyle = {
      background: 'white',
      borderRadius: '9px',
      color: '',
      fontWeight: 200,
    };
    return (
      <div>
        <Helmet>
          <title>Usnergy consulting - Our Founder</title>
          <meta
            name="description"
            content="Our mission at Usnergy consulting is to be a deeply
                    technical, commercial, and entrepreneurial organisation."
          />
          <link rel="canonical" href="/about" />
        </Helmet>
        <NavBar />
        <div className="hero-about founder-bg">
          <div className="container">
            <Row>
              <Col sm>
                <div className="header-text">
                  <h3 className="wow fadeInUp">Our Founder.</h3>
                  <p className="wow fadeInUp ">
                    <i>
                      "Leadership is not about being in charge. It's about
                      taking care of those in your charge." - Tom Gustafsson
                    </i>
                  </p>
                </div>
              </Col>
              <Col sm></Col>
            </Row>
          </div>
        </div>

        <div className="safety-write" style={{ paddingTop: 150 }}>
          <div className="container">
            <Row>
              <Col sm>
                <img
                  src={tomPic}
                  alt="tomPic"
                  style={{ width: '60%', paddingBottom: 50 }}
                />
                <p className="p-text">
                  <strong>Name:</strong> Tom Gustafsson
                </p>
                <p className="p-text">
                  Through years of dedicated work, I have built a vast network
                  of reputable manufacturers, ensuring a wide range of options
                  and the availability of essential Security and defense
                  equipment. I understand the urgency of your requirements. As
                  your liaison, I prioritize timely communication, streamlined
                  processes, and prompt delivery to meet your organization's
                  needs effectively.
                </p>
                <p className="p-text">
                  Navigating the complex landscape of Security and defense
                  equipment procurement can be challenging. I offer expert
                  guidance and personalized support, helping you make informed
                  decisions that align with your organization's objectives.
                  Whether it's our engineers, our project managers or our
                  leadership teams, we have a diverse workforce I am committed
                  to maintaining the highest standards of quality in every
                  aspect of our services. Rest assured, I only collaborate with
                  manufacturers that meet stringent criteria and adhere to
                  regulatory guidelines.
                </p>
                <p className="p-text">
                  Every organization is unique, and I recognize the importance
                  of tailored solutions. I work closely with you to understand
                  your specific requirements and provide customized
                  recommendations that best suit your needs.
                </p>
                <p className="p-text">
                  We aim to attract and develop the very best talent and in
                  order to do that, we continue to build a culture that is
                  inclusive and supportive. We want all of our employees to
                  reach their full potential and feel valued for their
                  contribution. We are focused on maintaining our position as a
                  leading employer of choice, particularly in the areas
                  essential to our business of engineering, manufacturing,
                  science and technology.
                </p>
              </Col>
            </Row>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Founder;
