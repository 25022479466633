import React, { Component } from 'react';

import 'react-vertical-timeline-component/style.min.css';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import WOW from 'wowjs';

import NavBar from '../components/NavBar/NavBar';
import Footer from '../components/Footer/Footer';

import './Assets.css';

import sensors from './image/sensor.jpg';
import surveillance from './image/surveilannce.jpg';
import display from './image/display.jpg';
import navigation from './image/navigation.jpg';
import warfare from './image/warfare.jpg';

import { Helmet } from 'react-helmet-async';

class Assets extends Component {
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>Usnergy consulting - What we do</title>
          <meta
            name="description"
            content="Our mission at Usnergy consulting is to be a deeply
                    technical, commercial, and entrepreneurial organisation."
          />
          <link rel="canonical" href="/assets" />
        </Helmet>
        <NavBar />
        <div className="hero-assets assets-bg">
          <div className="container">
            <Row>
              <Col sm>
                <div className="header-text">
                  <h3 className="wow fadeInUp">What we Do</h3>
                  <p className="wow fadeInUp ">
                    Usnergy is committed to providing comprehensive support for
                    the success of every project. Our services encompass the
                    entire range of support, spanning from consulting and
                    information management to security and logistics. We
                    customize these services to align with the unique needs of
                    each client, ensuring a tailored approach to meet individual
                    requirements.
                  </p>
                </div>
              </Col>
              <Col sm></Col>
            </Row>
          </div>
        </div>
        <div class="juice2" style={{ paddingTop: 100 }}>
          <div className="container">
            <Row>
              <Col sm>
                <div className="never wow fadeInUp">
                  <h3>Sensor System</h3>
                  <p>
                    We provide superior situational awareness and targeting. Our
                    advanced sensor systems integrate and collate information
                    rapidly to give commanders superior situational awareness
                    and targeting solutions. We provide and support major
                    surface ship radar systems and state-of-the-art land-based
                    radars. We also have leading capability in meteorological
                    and oceanographic systems.
                  </p>
                </div>
              </Col>
              <Col sm>
                <div className="never wow fadeInUp">
                  <Row>
                    <Col sm>
                      <h3></h3>
                      <img src={sensors} alt="sensors" />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm>
                <div className="never wow fadeInUp">
                  <h3>Survelliance</h3>
                  <p>
                    We provide high-resolution airborne persistent surveillance,
                    identification systems, and signals intelligence for our
                    customers. We also have a proven history of delivering
                    radiation hardened space-ready solutions
                  </p>
                </div>
              </Col>
              <Col sm>
                <div className="never wow fadeInUp">
                  <Row>
                    <Col sm>
                      <h3></h3>
                      <img src={surveillance} alt="surveillance" />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm>
                <div className="never wow fadeInUp">
                  <h3>Optical display and Targetting</h3>
                  <p>
                    Our optical displays and targeting capabilities provide a
                    wide range of innovative, flexible solutions for unique
                    customer needs. They include precision guidance systems,
                    head-up displays, situational awareness displays, gun fire
                    control, threat-warning infrared countermeasures for
                    aircraft, and handheld targeting and thermal devices.
                  </p>
                </div>
              </Col>
              <Col sm>
                <div className="never wow fadeInUp">
                  <Row>
                    <Col sm>
                      <h3></h3>
                      <img src={display} alt="display" />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm>
                <div className="never wow fadeInUp">
                  <h3>Navigation & Imaging Systems Products</h3>
                  <p>
                    We develop, manufacture, integrate, and support GPS
                    receivers and guidance systems for advanced military
                    applications, offering our customers complete solutions. Our
                    visible and infrared imaging sensors are designed and
                    developed for various commercial and military vision
                    systems.
                  </p>
                </div>
              </Col>
              <Col sm>
                <div className="never wow fadeInUp">
                  <Row>
                    <Col sm>
                      <h3></h3>
                      <img src={navigation} alt="navigation" />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm>
                <div className="never wow fadeInUp">
                  <h3>Electronic warfare</h3>
                  <p>
                    Our electronic warfare systems detect and defeat advanced
                    radio frequency threats.Our electronic warfare systems equip
                    tactical fighters, global strike and unmanned vehicles to
                    detect, identify, locate, deny, and defeat advanced radio
                    frequency threat systems. We develop innovative war
                    technologies that deliver unmatched situational awareness,
                    broadband electronic attack, passive targeting,
                    full-spectrum countermeasures, and data link communications
                    with low probability of detection and low probability of
                    intercept.
                  </p>
                </div>
              </Col>
              <Col sm>
                <div className="never wow fadeInUp">
                  <Row>
                    <Col sm>
                      <h3></h3>
                      <img src={warfare} alt="warfare" />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Assets;
