import React from 'react';
import NavBar from '../components/NavBar/NavBar';
import Footer from '../components/Footer/Footer';
import ContactHero from './ContactHero/ContactHero';
import { Helmet } from 'react-helmet-async';

export default function Contact() {
  return (
    <div>
      <Helmet>
        <title>Usnergy consulting - Contact Page</title>
        <meta
          name="description"
          content="Our mission at Usnergy consulting is  To help our clients make distinctive, lasting, and substantial improvements in their performance and to build a great firm that attracts, develops, excites, and retains exceptional people."
        />
        <meta
          http-equiv="Content-Security-Policy"
          content="script-src 'self' 'unsafe-inline' 'unsafe-eval' https://maps.googleapis.com"
        />

        <link rel="canonical" href="/contact" />
      </Helmet>
      <NavBar />
      <ContactHero />
      <Footer />
    </div>
  );
}
