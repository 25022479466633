import React, { Component } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Link } from 'react-router-dom';

import './Footer.css';

class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="container">
          <Row>
            <Col sm>
              <div className="footer-col">
                <h3>Company</h3>
                <p>
                  Usnergy consulting - To help our clients make distinctive,
                  lasting, and substantial improvements in their performance and
                  to build a great firm that attracts, develops, excites, and
                  retains exceptional people.
                </p>
              </div>
            </Col>
            <Col sm>
              <div className="footer-col">
                <h3>Links</h3>
                <p>
                  <Link className="footer-link" to="/">
                    Home
                  </Link>
                </p>
                <p>
                  <Link className="footer-link" to="/about">
                    About Us
                  </Link>
                </p>
                <p>
                  <Link className="footer-link" to="/contact">
                    Contact Us
                  </Link>
                </p>
              </div>
            </Col>
            <Col sm>
              <div className="footer-col">
                <h3>More</h3>
                <p>
                  <Link className="footer-link" to="/products-and-services">
                    Products & Services
                  </Link>
                </p>
                <p>
                  <Link className="footer-link" to="/what-we-do">
                    What we do
                  </Link>
                </p>
                <p>
                  <Link className="footer-link" to="/our-founder">
                    Our Founder
                  </Link>
                </p>
              </div>
            </Col>

            {/* <Col sm>
              <div className="footer-col">
                <h3>Other</h3>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3247.286663209838!2d-97.54752114942391!3d35.521913046203835!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87b21a14bc909105%3A0x87ccdc4501ab336b!2s1900%20Northwest%20Expy%20%23825%2C%20Oklahoma%20City%2C%20OK%2073118%2C%20USA!5e0!3m2!1sen!2sng!4v1623279438839!5m2!1sen!2sng"
                  className="map"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  title="ma"
                  loading="lazy"
                  aria-hidden="false"
                  tabindex="0"
                ></iframe>
              </div>
            </Col> */}
          </Row>
        </div>
        <div className="footer-bootom" style={{ paddingTop: 50 }}>
          {/* <p>600 E Las Colinas Blvd, Suite 1230, Irving, TX 75039</p> */}
          <p>
            Usnergy consulting. © 2024. All rights reserved. * Service may be
            provided by third parties and be subject to additional terms
          </p>
        </div>
      </div>
    );
  }
}

export default Footer;
