import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import WOW from 'wowjs';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import './ContactHero.css';

export class ContactHero extends Component {
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }

  render() {
    return (
      <div className="contact">
        <div className="contact-hero">
          <div className="container">
            <Row>
              <Col>
                <div className="container">
                  <div className="contact-header-text wow fadeInDown">
                    Contact Us
                  </div>
                </div>
                <br />
                <br />
              </Col>
            </Row>
          </div>
        </div>

        <div
          style={{ paddingTop: 100, paddingBottom: 100 }}
          className="contact-info"
        >
          <div className="contact-form container">
            <h4 style={{ color: 'black' }}>Contact Us</h4>
            <Form>
              <Form.Row>
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" placeholder="" />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridPassword">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" placeholder="" />
                </Form.Group>
              </Form.Row>

              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" rows="3" />
              </Form.Group>

              <Button variant="primary" type="submit">
                Submit
              </Button>
              <br />
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactHero;
