// import React, { useEffect, useState } from "react";
import Navbar from 'react-bootstrap/Navbar';

import Nav from 'react-bootstrap/Nav';
// import FormControl from "react-bootstrap/FormControl";

import { Link } from 'react-router-dom';
import logoPng from './logo.png';

import './NavBar.css';

import React, { Component } from 'react';
import { Navbar as NewNav } from 'react-responsive-navbar-overlay';
import { BrowserRouter } from 'react-router-dom';

export class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navBackground: 'black',
      mobile: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    // document.addEventListener("scroll", () => {
    //   const backgroundcolor = window.scrollY < 100 ? "" : "#3B3035";

    //   this.setState({ navBackground: backgroundcolor });
    // });
  }

  render() {
    return !this.state.mobile ? (
      <div>
        <Navbar
          collapseOnSelect
          expand="lg"
          className="navbar-header"
          fixed="top"
          style={{ backgroundColor: this.state.navBackground }}
        >
          <div className="container">
            <Navbar.Brand
              className="brandlogo"
              as={Link}
              to="/"
              id="brand-text"
            >
              {/* <img
                alt="Usnergy consulting Logo"
                src={logoPng}
                width="70"
                height="70"
                className="logo"
              /> */}
              Usnergy consulting
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              style={{ backgroundColor: '#CEAA4D' }}
            />

            <Navbar.Collapse id="responsive-navbar-nav menu">
              <Nav className="ml-auto nav-text">
                {/* <Nav.Link as={Link} to="/" id="nav-text">
                  Home
                </Nav.Link> */}
                <Nav.Link as={Link} to="/about" id="nav-text">
                  About us
                </Nav.Link>
                <Nav.Link as={Link} to="/products-and-services" id="nav-text">
                  Products & Services
                </Nav.Link>
                <Nav.Link as={Link} to="/what-we-do" id="nav-text">
                  What we do
                </Nav.Link>
                <Nav.Link as={Link} to="/our-founder" id="nav-text">
                  Our Founder
                </Nav.Link>

                <Nav.Link as={Link} to="/contact" id="nav-text">
                  Contact
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </div>
    ) : (
      <div>
        <BrowserRouter>
          <NewNav
            className="navbar-header"
            fixed="top"
            style={{ backgroundColor: this.state.navBackground }}
          />
        </BrowserRouter>
      </div>
    );
  }
}

export default NavBar;

// export default function NavBar() {
//   const { pathname } = useLocation();

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   window.addEventListener("scroll", () => {
//     if (window.scrollY > 20) {
//       document.querySelector(".navbar-header").className = "navbar-header scro";
//     } else {
//       document.querySelector(".navbar-header").className = "navbar-header";
//     }
//   });

//   return (
//     <div>
//       <Navbar
//         collapseOnSelect
//         expand="lg"
//         className="navbar-header"
//         fixed="top"
//       >
//         <div className="container">
//           <Navbar.Brand
//             className="brandlogo"
//             as={Link}
//             to="/"
//             id="brand-text"
//             style={{ fontWeight: 700 }}
//           >
//             <img alt="" src={logoPng} width="40" height="50" className="" />
//             &nbsp;&nbsp;upholdbank
//           </Navbar.Brand>
//           <Navbar.Toggle aria-controls="responsive-navbar-nav" />
//           <Navbar.Collapse id="responsive-navbar-nav">
//             <Nav className="mr-auto nav-text">
//               <Nav.Link as={Link} to="/about" id="nav-text">
//                 About
//               </Nav.Link>
//               <Nav.Link as={Link} to="/" id="nav-text">
//                 Plan
//               </Nav.Link>
//               <Nav.Link as={Link} to="/" id="nav-text">
//                 Save
//               </Nav.Link>
//               <Nav.Link as={Link} to="/" id="nav-text">
//                 Invest
//               </Nav.Link>
//             </Nav>
//             <Form inline>
//               <Button
//                 as={Link}
//                 to={{ pathname: "user " }}
//                 target="_blank"
//                 variant="outline-success"
//                 style={{ fontWeight: "bold" }}
//               >
//                 LOGIN
//               </Button>
//               &nbsp;&nbsp;
//               <Button
//                 as={Link}
//                 to={{
//                   pathname:
//                     "https://play.google.com/store/apps/details?id=com.uphold.wallet&hl=en&gl=US ",
//                 }}
//                 target="_blank"
//                 variant="success"
//                 style={{ fontWeight: "bold" }}
//               >
//                 CREATE ACCOUNT
//               </Button>
//             </Form>
//           </Navbar.Collapse>
//         </div>
//       </Navbar>
//     </div>
//   );
// }
